(function (angular, _, undefined) {
    "use strict";
    var core = angular.module("mikesCore");

    core.factory("AuthService", ["$http", "$state", "$sessionStorage", "$mdToast", "$q",
        function ($http, $state, $sessionStorage, $mdToast, $q) {
        return {
            isLoggedIn: function () {
                return !!$sessionStorage.user;
            },

            localLogin: function (email, password, cb) {
                $http
                    .post("/api/auth/login", { email: email, password: password })
                    .then(function (res) {
                        if (_.has(res, "data.error")) {
                            $state.go(_.get(res, "data.redirect", "login"));
                            $mdToast.showSimple(_.get(res, "data.message", "An error occured! (1)"));
                        } else if (_.has(res, "data.fail")) {
                            return cb(_.get(res, "data.message", "Failed to log in!"));
                        } else if (!_.has(res, "data.user")) {
                            $state.go(_.get(res, "data.redirect", "login"));
                            $mdToast.showSimple(_.get(res, "data.message", "Error! No user data found!"));
                        } else {
                            $sessionStorage.user = res.data.user;
                            $state.go(_.get(res, "data.redirect", "local"));
                        }
                        return cb();
                    })
                    .catch(function (err) {
                        $state.go(_.get(err, "data.redirect", "login"));
                        $mdToast.showSimple(_.get(err, "data.message", "An error occured! (2)"));
                    });
            },
            logout: function () {
                $http
                    .get("/api/auth/logout")
                    .then(function (res) {
                        $sessionStorage.user = null;
                        $state.go(_.get(res, "data.redirect", "login"));
                    })
                    .catch(function (err) {
                        $mdToast.showSimple(_.get(err, "data.message", "An error occured! (3)"));
                        $state.go(_.get(err, "data.redirect", "login"));
                    });
            }
        };
    }]);

    core.factory("AssetService", ["$http", "$q", "$localStorage",
        function ($http, $q, $localStorage) {
        return {
            getAsset: function (asset) {
                var after = "",
                    def = $q.defer();
                if (typeof $localStorage.assets !== "object") {
                    $localStorage.assets = {};
                }
                if (typeof $localStorage.assets[asset] !== "object") {
                    $localStorage.assets[asset] = {};
                }
                if (_.has($localStorage.assets[asset], "date")) {
                    after = "?after=" + (new Date($localStorage.assets[asset].created)).getTime();
                }
                $http.get("/api/asset/name/" + asset + after)
                    .then(function (res) {
                        if (res.data) {
                            $localStorage.assets[asset].created = _.get(res.data, "date");
                            $localStorage.assets[asset].name = _.get(res.data, "name");
                            $localStorage.assets[asset].title = _.get(res.data, "title");
                            $localStorage.assets[asset].body = _.get(res.data, "body");
                        }
                        def.resolve();
                    }, function (err) {
                        def.reject(err);
                    });

                return def.promise;
            }
        };
    }]);
})(angular, _);
